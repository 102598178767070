import React, {Fragment} from 'react';
import {formatValidator} from '../Utils';
import {LOVECODE_OPTIONS} from '../dictionary';
import {
  Radio,
  RadioGroup,
  RadioContent,
  Text,
  Row,
  Input,
  Label,
  ErrorHint,
  Hint,
  Divider,
  Checkbox,
  Button,
  Select,
  SelectOption,
} from '../Widgets';
import {Link} from 'gatsby';
import {INVOICE_TYPES, TWO_COPIES_TYPES} from '../dictionary';
const appConfig = require('../data.json');

const {
  isPersonCertificatedCode,
  isMobileVehicleCode,
  isCompanyId,
  isLoveCode,
  isNotEmpty,
} = formatValidator;

export default function InvoiceForm(props) {
  const {setConfig: _setConfig, config, validate = false, setAgree} = props;

  function setConfig(obj) {
    _setConfig({
      ...config,
      ...obj,
    });
  }

  return (
    <RadioGroup
      value={config.invoice_type}
      onChange={(e) =>
        setConfig({
          invoice_type: e.target.value,
        })
      }>
      <div>
        <Row>
          <Radio value={INVOICE_TYPES.two_copies.value}>二聯式電子發票</Radio>
        </Row>
        {config.invoice_type === INVOICE_TYPES.two_copies.value && (
          <RadioContent>
            <Row>
              <Label>發票類型</Label>
              <Select
                value={config.invoice_subtype}
                onChange={(value) => {
                  setConfig({invoice_subtype: value});
                }}>
                <SelectOption value={TWO_COPIES_TYPES.ezpay_vehicle.value}>
                  {TWO_COPIES_TYPES.ezpay_vehicle.label}
                </SelectOption>
                <SelectOption
                  value={TWO_COPIES_TYPES.citizen_personal_certificate.value}>
                  {TWO_COPIES_TYPES.citizen_personal_certificate.label}
                </SelectOption>
                <SelectOption value={TWO_COPIES_TYPES.mobile_vehicle.value}>
                  {TWO_COPIES_TYPES.mobile_vehicle.label}
                </SelectOption>
              </Select>
            </Row>

            {config.invoice_subtype ===
            TWO_COPIES_TYPES.citizen_personal_certificate.value ? (
              <Row>
                <Label>條碼編號</Label>
                <Input
                  placeholder="請輸入自然人憑證條碼16碼"
                  value={config.citizen_personal_certificate_code}
                  onChange={(e) =>
                    setConfig({
                      citizen_personal_certificate_code: e.target.value,
                    })
                  }
                />
                {validate &&
                  config.invoice_type === INVOICE_TYPES.two_copies.value &&
                  config.invoice_subtype ===
                    TWO_COPIES_TYPES.citizen_personal_certificate.value &&
                  (!isNotEmpty(config.citizen_personal_certificate_code) ||
                    !isPersonCertificatedCode(
                      config.citizen_personal_certificate_code,
                    )) && (
                    <ErrorHint>
                      請確認自然人憑證條碼格式(2碼英文字母加上14碼數字)
                    </ErrorHint>
                  )}
              </Row>
            ) : config.invoice_subtype ===
              TWO_COPIES_TYPES.mobile_vehicle.value ? (
              <Row>
                <Label>條碼編號</Label>
                <Input
                  placeholder="請輸入手機載具條碼8碼"
                  value={config.mobile_vehicle_code}
                  onChange={(e) => {
                    setConfig({mobile_vehicle_code: e.target.value});
                  }}
                />
                {validate &&
                  config.invoice_type === INVOICE_TYPES.two_copies.value &&
                  config.invoice_subtype ===
                    TWO_COPIES_TYPES.mobile_vehicle.value &&
                  (!isNotEmpty(config.mobile_vehicle_code) ||
                    !isMobileVehicleCode(config.mobile_vehicle_code)) && (
                    <ErrorHint>
                      請確認手機載具條碼格式(斜線(/)加上7碼數字或大寫字母)
                    </ErrorHint>
                  )}
              </Row>
            ) : null}
          </RadioContent>
        )}
      </div>

      <div>
        <Row>
          <Radio value={INVOICE_TYPES.three_copies.value}>三聯式電子發票</Radio>
          <Hint>＊開立抬頭統編公司報帳用發票，請勾選此項目。</Hint>
        </Row>

        {config.invoice_type === INVOICE_TYPES.three_copies.value && (
          <RadioContent>
            <Row>
              <Label>統一編號</Label>
              <Input
                placeholder="請輸入公司統編"
                value={config.gui_number}
                onChange={(e) => setConfig({gui_number: e.target.value})}
              />
              {validate &&
                config.invoice_type === INVOICE_TYPES.three_copies.value &&
                (!isNotEmpty(config.gui_number) ||
                  !isCompanyId(config.gui_number)) && (
                  <ErrorHint>請確認統一編號格式</ErrorHint>
                )}
            </Row>

            <Row>
              <Label>公司抬頭</Label>
              <Input
                placeholder="請輸入公司抬頭"
                value={config.company_title}
                onChange={(e) => setConfig({company_title: e.target.value})}
              />
              {validate &&
                config.invoice_type === INVOICE_TYPES.three_copies.value &&
                !isNotEmpty(config.company_title) && (
                  <ErrorHint>請輸入公司抬頭</ErrorHint>
                )}
              <Button
                type="link"
                target="_blank"
                href="https://www.etax.nat.gov.tw/cbes/web/CBES113W1_1">
                查詢公司抬頭
              </Button>
            </Row>
          </RadioContent>
        )}
      </div>

      <div>
        <Row>
          <Radio value={INVOICE_TYPES.donate.value}>捐贈發票</Radio>
        </Row>
        {config.invoice_type === INVOICE_TYPES.donate.value && (
          <RadioContent>
            <Row>
              <Label>愛心碼</Label>
              <Select
                value={config.love_code}
                onChange={(value) => {
                  setConfig({love_code: value});
                }}>
                {LOVECODE_OPTIONS.map((x) => (
                  <SelectOption key={x.value} value={x.value}>
                    {x.label}
                  </SelectOption>
                ))}
              </Select>
              {/* <TextInput
                    placeholder="請輸入愛心碼"
                    value={config.love_code}
                    onChange={e =>
                      setConfig({ ...config, love_code: e.target.value })
                    }
                  />
                  {validate &&
                    config.invoice_type ===
                      INVOICE_TYPES.donate.value &&
                    (!isNotEmpty(config.love_code) ||
                      !isLoveCode(config.love_code)) &&
                    ErrorHint({ message: "請確認愛心碼格式(3至7位數字)" })} */}
            </Row>
          </RadioContent>
        )}
      </div>

      {setAgree && (
        <>
          <Divider />
          <Row css={{alignItems: 'flex-start'}}>
            <Checkbox
              defaultChecked={false}
              onChange={(e) => {
                setAgree({invoice: e.target.checked});
              }}
            />
            <div style={{marginLeft: 10}}>
              <Text size="sm" style={{marginBottom: 10}}>
                ＊我同意辦理退貨時，由{appConfig.information.name}
                代為處理電子發票及銷貨退回證明單，以加速退貨退款作業。
              </Text>
              <Text size="sm" style={{marginBottom: 10}}>
                ＊個人(二聯式)發票一經開立，無法更改或改開公司戶(三聯式)發票。
              </Text>
              <Text size="sm" style={{marginBottom: 10}}>
                ＊根據財政部「電子發票實施作業要點」，於消費開立電子發票不主動寄送，會將發票號碼上傳至政府平台。
              </Text>
            </div>
          </Row>
        </>
      )}
    </RadioGroup>
  );
}
