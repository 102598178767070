import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {Context} from '../../AppContext';
import EmptyCart from './EmptyCart';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Steps from './Steps';
import CartBag from './CartBag';
import Checkout from './Checkout';
import UnAuthResult from '../../Components/UnAuthResult';
import {navigate} from 'gatsby';
import PaymentSelect from './PaymentSelect';
import Tracking from '../../Tracking';

export default function CartPage(props) {
  const app = useContext(Context);
  const [step, setStep] = useState(0);
  const [config, setConfig] = useState({
    userConfig: {
      name: '',
      phone: '',
      email: '',
      tel: '',
      tel_ext: '',
      zip: '',
      address: '',
    },
    deliveryConfig: {
      delivery_type: 'hct',
      is_delivery_private: false,
      receiver_name: '',
      receiver_phone: '',
      receiver_phone_spare: '',
      receiver_tel: '',
      receiver_tel_ext: '',
      receiver_address: '',
      zip: '',
      delivery_note: '',
      // private delivery data:
      sender_name: '',
      sender_phone: '',
      sender_tel: '',
      sender_tel_ext: '',
      sender_address: '',
      sender_zip: '',
    },
    invoiceConfig: {
      invoice_type: 'two_copies',
      invoice_subtype: 'ezpay_vehicle',
      invoice_detail: '',

      citizen_personal_certificate_code: '', //自然人憑證條碼(16碼)
      mobile_vehicle_code: '', //手機載具(8碼)
      ezpay_vehicle_code: '',

      gui_number: '',
      company_title: '',

      donate_foundation_gui: '',
      love_code: '',
    },
    extraConfig: {
      use_bonus: false,
    },
  });
  let prevDeliveryType = useRef('hct');
  const [params, setParams] = useState({
    payment_type: '',
    payment_subtype: '',
    note: '',
  });

  const {cart, profile} = app.state;

  const autofill = useCallback(() => {
    if (cart && profile) {
      const {config: _config} = cart;

      //user
      let userConfig = {
        name: _config.name || profile.name,
        phone: _config.phone || profile.phone,
        email: _config.email || profile.email,
        tel: _config.tel,
        tel_ext: _config.tel_ext,
        zip: _config.zip,
        address: _config.address || profile.address,
      };

      //delivery
      //not fill, default 新竹物流

      //invoice
      let invoiceConfig = _config.invoiceConfig || config.invoiceConfig;

      //other config
      let extraConfig = {
        use_bonus: _config.use_bonus,
      };

      setConfig({
        userConfig,
        deliveryConfig: config.deliveryConfig,
        invoiceConfig,
        extraConfig,
      });
    }
  }, [cart, profile]);

  // inital autofill
  useEffect(() => {
    if (cart && profile) {
      autofill();
    }
  }, [cart, profile, autofill]);

  const updateConfig = useCallback(async () => {
    app.actions.setLoading(true);
    const {userConfig, deliveryConfig, invoiceConfig, extraConfig} = config;

    try {
      let resp = await app.actions.editConfig({
        ...userConfig,
        ...extraConfig,
        deliveryConfig,
        invoiceConfig,
      });
      autofill();
    } catch (ex) {
      console.warn(ex);
    }
    app.actions.setLoading(false);
  }, [app.actions, autofill, config]);

  // delivery_type changed, then update cart.config
  useEffect(() => {
    if (prevDeliveryType.current !== config.deliveryConfig.delivery_type) {
      prevDeliveryType.current = config.deliveryConfig.delivery_type;
      updateConfig();
    }
  }, [config.deliveryConfig.delivery_type, updateConfig]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [step]);

  useEffect(() => {
    if (step >= 0 && cart) {
      Tracking.checkout(step, cart, params);
    }
  }, [step, cart, params]);

  async function createOrder() {
    const order = await app.actions.checkout(params);

    Tracking.checkout(step, cart, params);
    navigate(`/order?id=${order.id}`);
  }

  if (!profile) {
    return <UnAuthResult />;
  }

  if (!cart) {
    return null;
  }
  if (cart.items.length === 0) {
    return <EmptyCart />;
  }

  function changeStep(next = true) {
    setStep((prev) => prev + (next ? 1 : -1));
  }

  return (
    <ContentWrapper>
      <Steps step={step} />
      {step === 0 && (
        <CartBag
          goToPrevStep={() => navigate('/products')}
          goToNextStep={() => changeStep()}
        />
      )}

      {step === 1 && (
        <Checkout
          goToPrevStep={() => changeStep(false)}
          goToNextStep={() => changeStep()} //
          config={config}
          setConfig={(type, config) => {
            setConfig((prev) => {
              return {...prev, [`${type}Config`]: config};
            });
          }}
          updateConfig={updateConfig}
          params={params}
          setParams={(obj) => setParams((prev) => ({...prev, ...obj}))}
        />
      )}

      {step === 2 && (
        <PaymentSelect
          goToPrevStep={() => changeStep(false)}
          goToNextStep={() => createOrder()} //
          params={params}
          setParams={(obj) => setParams((prev) => ({...prev, ...obj}))}
        />
      )}
    </ContentWrapper>
  );
}
