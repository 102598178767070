import React, {useContext, useState} from 'react';
import {Button} from '../../Widgets';
import CartItemTable from '../../Components/CartItemTable';
const config = require('../../data.json');

export default function Bag(props) {
  const {goToNextStep, goToPrevStep} = props;
  return (
    <div>
      <CartItemTable />

      {goToNextStep && (
        <div style={{display: 'flex', alignItems: 'center', margin: '20px 0'}}>
          <Button
            type="default"
            style={{
              color: config.colors.borderSecond,
              borderColor: config.colors.borderSecond,
            }}
            onClick={goToPrevStep}>
            繼續購物
          </Button>
          <div style={{flex: 1}}></div>
          <Button onClick={goToNextStep}>下一步</Button>
        </div>
      )}
    </div>
  );
}
