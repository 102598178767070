import React, {useContext} from 'react';
import {PAYMENT_TYPES} from '../dictionary';
import {Context} from '../AppContext';
import {isVIP, isMonthly} from '../Utils/UserUtil';

export default function usePaymentTypeOptions() {
  const app = useContext(Context);
  const {cart, profile} = app.state;
  let result = {
    options: [],
    message: '',
  };

  if (cart && profile) {
    result.options = PAYMENT_TYPES.filter((option) => {
      if (isVIP(profile)) {
        return option.memberType === 'vip';
      } else if (isMonthly(profile)) {
        return option.memberType === 'monthly';
      } else {
        return !option.memberType;
      }
    }).filter((option) => {
      if (
        cart &&
        (cart.calculations.amount < 30 || cart.calculations.amount >= 20000)
      ) {
        // 超商代碼繳費 cvs allow amount between 30 and 20000
        return option.value !== 'cvs';
      }
      return true;
    });

    if (cart.calculations.amount < 30 || cart.calculations.amount >= 20000) {
      result.message = '超商付款需在30元 ~ 19999元區間';
    }
  }

  return result;
}
