import React, {Fragment, useContext, useState} from 'react';
import {Row as AntRow, Col, Alert, message as antMessage} from 'antd';
import {Context} from '../../AppContext';
import {
  Expander,
  FormExpander,
  Text,
  Button,
  Row,
  Radio,
  RadioGroup,
  Heading,
  Divider,
} from '../../Widgets';
import CartItemTable from '../../Components/CartItemTable';
import {ExpandMore, ExpandLess} from '@styled-icons/material';
import Calculation from '../../Components/Calculation';
import usePaymentTypeOptions from '../../hooks/use-payment-type-options';
import {ErrCheckoutForm} from '../../errors';
import Image from '../../Components/Image';
const appConfig = require('../../data.json');

export default function PaymentSelect(props) {
  const {goToPrevStep, goToNextStep, params, setParams} = props;
  const app = useContext(Context);
  let {cart} = app.state;
  const [type, setType] = useState(null);
  const {options, message} = usePaymentTypeOptions();

  if (!cart) {
    return null;
  }

  let amount = cart.calculations.amount;
  let length = cart.items.length;

  function onChange(value) {
    setType(value);
    const mapping = {
      atm: {payment_type: 'neweb', payment_subtype: value},
      credit: {payment_type: 'neweb', payment_subtype: value},
      cvs: {payment_type: 'neweb', payment_subtype: value},
      credits: {payment_type: value, payment_subtype: 'default'},
      monthly: {payment_type: value, payment_subtype: 'default'},
    };
    setParams(mapping[value]);
  }

  function valid() {
    if (!type) {
      throw new ErrCheckoutForm('請選擇一種繳費方式');
    }
  }

  async function submit() {
    app.actions.setLoading(true);

    try {
      valid();
      await goToNextStep();
    } catch (ex) {
      if (ex instanceof ErrCheckoutForm) {
        antMessage.error(ex.message);
      }
      console.warn(ex);
    }
    app.actions.setLoading(false);
  }

  return (
    <div>
      <Heading align="center" weight="400">
        訂單總計金額(含稅)
        <span style={{color: appConfig.colors.main}}> ${amount}</span>
      </Heading>
      <Expander
        expand={false}
        title={`總計${length}件商品`}
        titleStyle={{flex: '0 0 auto'}}
        containerStyle={{
          padding: 10,
          justifyContent: 'center',
        }}
        ExpandMoreIcon={() => (
          <ExpandMore size={40} color={appConfig.colors.main} />
        )}
        ExpandLessIcon={() => (
          <ExpandLess size={40} color={appConfig.colors.main} />
        )}>
        <CartItemTable viewOnly={true} />
      </Expander>

      <div style={{height: 20}} />

      <FormExpander title="付款方式">
        <div
          style={{
            textAlign: 'center',
            marginBottom: 20,
            padding: 20,
          }}>
          <div style={{fontWeight: '600', fontSize: 14, marginBottom: 10}}>
            {appConfig.information.name}
            由藍新金流於華南銀行開立信託專戶，專款專用安心有保障
          </div>
          <p style={{fontSize: 12}}>
            本網站採用先收款後製作，付款成功後才算正式成立訂單。請於選擇之付款方式截止日前進行繳費作業，付款方式如項目所示。
          </p>
          <p style={{fontSize: 12}}>
            若已過匯款期限，訂單將自動取消。未付款訂單保留七日後系統會自動取消，若需再次交易請重新下單，請注意您的權益。
          </p>
        </div>

        <RadioGroup
          onChange={(e) => onChange(e.target.value)}
          value={type}
          style={{width: '100%'}}>
          {options.map((option, idx) => {
            return (
              <Fragment>
                {idx !== 0 && <Divider paddingSize="lg" />}
                <Row key={idx}>
                  <Radio value={option.value}>
                    <Text>{option.label}</Text>
                  </Radio>
                  <Text color="#ccc" size="sm">
                    {option.intro}
                  </Text>
                </Row>
              </Fragment>
            );
          })}
        </RadioGroup>

        {message && <Alert message={message} type="info" />}
      </FormExpander>

      <AntRow style={{marginBottom: 40}}>
        <Col md={12} sm={24}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
              padding: 20,
            }}>
            <Image src="/images/checkout-hint.png" width="200" height="auto" />
          </div>
        </Col>
        <Col md={12} sm={24}>
          <Calculation calculations={cart.calculations} />

          <Row margin="20px 0">
            <Button type="default" onClick={goToPrevStep}>
              上一步
            </Button>
            <div style={{flex: 1}}></div>

            <Button
              onClick={() => {
                submit();
              }}>
              下一步
            </Button>
          </Row>
        </Col>
      </AntRow>
    </div>
  );
}
